.root {
  display: grid;
  grid-template-columns: minmax(650px, 1fr) 300px;
  grid-template-areas: 'leftBlock rightBlock' 'bottomBlock bottomBlock';
  grid-gap: 30px 20px;

  @media (--monitor) {
    grid-template-areas: 'leftBlock rightBlock' 'bottomBlock rightBlock';
  }
}

.leftBlock {
  grid-area: leftBlock;
}

.rightBlock {
  grid-area: rightBlock;
}

.bottomBlock {
  grid-area: bottomBlock;
}

.dayNavigation {
  margin-bottom: 20px;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 15px;
}

.select {
  width: 150px;
  margin-left: auto;
}

.CategoryFilter {
  margin-top: 15px;
  margin-bottom: 30px;
}

.schedule {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 650px;
  margin-left: -5px;
  margin-bottom: 30px;

  @media (--monitor) {
    gap: 30px 10px;
    width: 950px;
  }
}

.channel {
  max-width: 310px;

  &:nth-child(n + 6) {
    order: 2;
  }

  @media (--monitor) {
    &:nth-child(6),
    &:nth-child(7) {
      order: 0;
    }
  }
}

.channelTitle {
  padding-left: 7px;
  margin-bottom: 15px;
}

.moreButton {
  margin: 10px auto 0;
  display: block;
}

.breadcrumbsFooter {
  margin-bottom: 30px;
}

.promoAd {
  margin-bottom: 20px;
}

.rightEarAd {
  margin-bottom: 20px;
}

.superfooterAd {
  order: 1;
  width: 100%;
}

.contextAd {
  margin-top: 30px;
}
