.root {
  display: flex;
  flex-wrap: wrap;
}

.sectionLeft {
  display: flex;
  flex-direction: column;
  width: 650px;
  margin-right: 20px;

  @media (--monitor) {
    min-width: 940px;
    margin-right: 20px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 315px;
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media (--monitor) {
    width: 300px;

    &:nth-child(2n) {
      margin-right: 20px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.wrapper {
  position: relative;
}

.cardTitle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  color: var(--color-carbone-base);
  font-family: var(--manrope);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-extra-bold);
  line-height: var(--line-height-large);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.favoriteButton {
  position: relative;
  z-index: 5;
}

.cardChannel {
  margin-bottom: 10px;
}

.favorites {
  margin-bottom: 10px;

  h1 {
    font-size: var(--font-size-extra-large) !important;
    line-height: var(--line-height-extra-large) !important;
    margin-bottom: 20px;
  }
}

.favoritesList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
}

.cover {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 170px;
  margin-bottom: 10px;
  background-color: var(--color-alt-blue-1);
  border-radius: 5px;

  @media (--monitor) {
    width: 300px;
  }
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.scheduleTitle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.categoryFilter {
  margin-top: 15px;
  margin-bottom: 30px;
}

.select {
  width: 150px;
  margin-left: auto;
}

.scheduleList {
  display: grid;
  grid-template-columns: repeat(2, 310px);
  grid-column-gap: 10px;
  margin-left: -5px;

  @media (--monitor) {
    grid-template-columns: repeat(3, 310px);
    grid-column-gap: 10px;
  }
}

.channel {
  margin-right: 30px;
  margin-bottom: 30px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  @media (--monitor) {
    margin-right: 10px;

    &:nth-child(2n) {
      margin-right: 10px;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.channelTitle {
  padding-left: 7px;
  margin-bottom: 15px;
}

.emptyShows {
  font-weight: var(--font-weight-default);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
}

.sectionRight {
  flex-shrink: 0;
  width: 300px;
  padding-bottom: 20px;
}

.sectionBottom {
  width: 100%;

  @media (--monitor) {
    width: 940px;
  }
}

.breadcrumbsFooter {
  margin-bottom: 30px;
}

.blueCard {
  border-radius: 10px;
  background-color: var(--color-alt-blue-1);
  padding: 20px 10px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & .cardInner {
    width: 425px;

    &.wide {
      width: 505px;
    }

    & h2 + p {
      margin-top: 5px;
    }

    & .button {
      margin-top: 20px;
      width: 125px;
    }
  }

  & + h2 {
    margin-top: 30px;
  }
}

.moreButton {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.promoAd {
  margin-bottom: 20px;
}

.rightEarAd {
  margin-bottom: 20px;
}

.superfooterAd {
  margin-bottom: 30px;
}

.premieres {
  margin-top: 30px;
}

.contextAd {
  margin-top: 30px;
}
