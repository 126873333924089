.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.small {
    width: 310px;
  }
}

.empty {
  color: var(--color-carbone-base);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
}

.nextList {
  display: none;

  &.visibleNext {
    display: block;
  }
}

.moreButton {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-blue-base);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
  transition: color 175ms;

  &:hover,
  &:focus {
    color: var(--color-blue-middle);
  }

  &:active {
    color: var(--color-blue-dark);
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.moreIcon {
  margin-right: 10px;
}

.lastItem {
  margin-bottom: 0 !important;
}
