.root {
  display: flex;
  flex-wrap: wrap;
}

.sectionLeft {
  display: flex;
  flex-direction: column;
  width: 650px;
  margin-right: 20px;
  padding-bottom: 30px;

  @media (--monitor) {
    min-width: 940px;
    margin-right: 20px;
  }
}

.sectionRight {
  flex-shrink: 0;
  width: 300px;
}

.sectionBottom {
  width: 100%;

  @media (--monitor) {
    width: 940px;
  }
}

.newsTitle {
  margin-bottom: 15px;
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  font-weight: var(--font-weight-extra-bold);
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  h1 {
    font-size: var(--font-size-extra-large) !important;
    line-height: var(--line-height-extra-large) !important;
  }
}

.geoButton {
  display: flex;
  margin-left: 5px;
  color: var(--color-blue-base);
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: var(--color-alt-blue-2);
  }
}

.select {
  width: 150px;
  margin-left: auto;
}

.CategoryFilter {
  margin-top: 15px;
  margin-bottom: 30px;
}

.schedule {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 650px;
  margin-left: -5px;
  margin-bottom: 30px;

  @media (--monitor) {
    gap: 30px 10px;
    width: 950px;
  }
}

.channel {
  max-width: 310px;

  &:nth-child(n + 6) {
    order: 2;
  }

  @media (--monitor) {
    &:nth-child(6),
    &:nth-child(7) {
      order: 0;
    }
  }
}

.channelTitle {
  padding-left: 7px;
  margin-bottom: 15px;
}

.moreButton {
  margin: 0 auto;
}

.recommend,
.breadcrumbsFooter {
  margin-bottom: 30px;
}

.promoAd {
  margin-bottom: 20px;
}

.rightEarAd {
  margin-bottom: 20px;
}

.superfooterAd {
  order: 1;
  width: 100%;
}
