.root {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.small {
    width: 310px;
  }
}

.empty {
  color: var(--color-carbone-base);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .small & {
    overflow: hidden;
    flex-wrap: wrap;

    &.emptyNextList {
      height: auto !important;
      padding-bottom: 0;
    }
  }

  &.visibleNext {
    height: auto !important;
    flex-wrap: nowrap;
    padding-bottom: 0;
  }
}

.episodes {
  display: none;

  &.visiblEpisodes {
    display: block;
  }
}

.nextButton,
.prevButton {
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 5px;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-semi-bold);

  .small & {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-default);
    line-height: var(--line-height-default);
  }

  /* stylelint-disable-next-line */
  &:hover {
    color: var(--color-blue-base);
  }

  &.openMoreList {
    svg {
      transform: rotate(90deg);
    }
  }
}

.prevButton {
  margin-bottom: 5px;

  .full & {
    margin-bottom: 15px;
  }
}

.moreEpisodesButton {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: var(--color-blue-base);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);
}

.moreEpisodesIcon {
  margin-right: 10px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 20px;
  margin-right: 15px;

  .small & {
    margin-right: 5px;
  }

  .full & {
    width: 40px;
  }
}

.show {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 5px;

  &.small {
    width: 310px;
  }

  &.withImage {
    padding: 10px 5px;
    border-top: 1px solid var(--color-alt-blue-2);
    border-bottom: 1px solid var(--color-alt-blue-2);
    border-radius: 0;
  }

  &.for-channel {
    margin-bottom: 15px;
  }

  &.for-show {
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 0;
  }

  &.sports {
    background-color: var(--color-green-1);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }

  &.kids {
    background-color: var(--color-pink);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }

  &.series {
    background-color: var(--color-alt-blue-1);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }

  &.movies {
    background-color: var(--color-yellow-1);
    border-radius: 5px;
    border-top: none;
    border-bottom: none;
  }
}

.dateStart {
  display: flex;
  flex-shrink: 0;
  width: 105px;
  margin-right: 15px;
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-default);
  line-height: var(--line-height-default);

  &.small {
    margin-right: 5px;
  }

  .for-channel & {
    font-size: var(--font-size-small);
  }

  .for-show & {
    width: auto;
    margin-right: 5px;
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-small);
  }
}

.weekend {
  color: var(--color-red-base);

  &::first-letter {
    text-transform: uppercase;
  }
}

.timeStart {
  width: 35px;
  flex-shrink: 0;
  margin-right: 15px;
  font-family: var(--manrope);
  color: var(--color-cloud-gray-dark);
  font-size: var(--font-size-small);
  line-height: var(--line-height-default);
  font-weight: var(--font-weight-default);

  .full & {
    width: 40px;
  }

  .small & {
    margin-right: 5px;
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-default);
    font-weight: var(--font-weight-default);

    &.active {
      color: var(--color-carbone-base);
    }
  }

  .for-show & {
    width: auto;
    font-size: var(--font-size-x-small);
    line-height: var(--line-height-small);
  }
}

.img {
  width: auto;
  height: 60px;
  margin-right: 15px;
  border-radius: 5px;
}
